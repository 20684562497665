import { fetchFirstEditionArticleById, fetchArticleById, fetchLatestArticle, fetchLatestFirstEditionArticle, fetchNextArticleId_ } from "./lib/dataSource/web3Fetcher";
import { getImageBufferFromURL } from "./lib/workflows/fetchImageFromCF";
import { algoliaClient, pushArticlesToAlgolia, pushFirstEditionArticlesToAlgolia } from "./lib/workflows/pushToAlgolia";
import { pushToR2 } from "./lib/workflows/pushToR2";
import { AlgoliaFetcher } from "./lib/algolia/algoliaFetcher";
import { createPublicClient, http } from "viem";
import { base } from 'viem/chains'
import { PublicClient } from "wagmi";
import { getArticlesIndex, getFirstEditionArticlesIndex } from "./lib/constants/algoliaIndicies"
import { ApiKeys, areKeysDecrypted } from "../context/ApiKeysContext";

async function main() {
    //@ts-ignore
    await updateIndicies(base)
    console.log("done")
}

export const fetchNextArticleId = async ():Promise<bigint> => {
    const _client = createPublicClient({
        chain: base,
        transport: http('https://base-mainnet.g.alchemy.com/v2/dzx6LjIVUMKqCGDKShF-dmYNuFrSMRcJ'),
    })
    const client = _client as PublicClient
    const nextId = await fetchNextArticleId_(client)
    return nextId
}

export const updateIndicies = async (keys:ApiKeys) => {
    if (!areKeysDecrypted()) {
        throw new Error("api keys not decrypted yet")
    }

    const _client = createPublicClient({
        chain: base,
        transport: http('https://base-mainnet.g.alchemy.com/v2/dzx6LjIVUMKqCGDKShF-dmYNuFrSMRcJ'),
    })
    const client = _client as PublicClient
    console.log("network:", base.name)
    
    getArticlesIndex(base)
    const algoliaFetcher = new AlgoliaFetcher(algoliaClient(keys), getArticlesIndex(base) as string)
    const firstEditionAlgoliaFetcher = new AlgoliaFetcher(algoliaClient(keys), getFirstEditionArticlesIndex(base) as string)

    // This is running in mockMode = true. Pulls out a mock article and pushes it to R2 and Algolia
    // You can replace with FetchArticleById
    console.log("starting regular article uploads...")
    let latestArticleIdChain = (await fetchLatestArticle(client)).articleId
    console.log("latest article ID on chain:", latestArticleIdChain)
    const latestArticleAlgolia = await algoliaFetcher.fetchLatestArticle()
    const latestArticleIdAlgolia = latestArticleAlgolia ? BigInt(latestArticleAlgolia.articleId) : 0n
    console.log("latest article ID on Algolia:", latestArticleIdAlgolia)
    for(let i = latestArticleIdAlgolia; i <= latestArticleIdChain; i++) {
        await cacheArticle(i, client, getArticlesIndex(base) as string, keys)
    }


    console.log("starting first edition article uploads...")
    let latestFirstEditionArticleIdChain = (await fetchLatestFirstEditionArticle(client)).articleId
    let latestFirstEditionArticleAlgolia = await firstEditionAlgoliaFetcher.fetchLatestArticle()
    let latestFirstEditionArticleIdAlgolia = latestFirstEditionArticleAlgolia ? BigInt(latestFirstEditionArticleAlgolia.articleId) :0n

    console.log("latest first edition article ID on chain:", latestFirstEditionArticleIdChain.toString())
    console.log("latest first edition article ID on algolia:", latestFirstEditionArticleIdAlgolia.toString())
    for(let i = 0n/*latestFirstEditionArticleIdAlgolia*/; i <= latestFirstEditionArticleIdChain; i++) {
        await cacheFirstEditionArticle(i, client, getFirstEditionArticlesIndex(base) as string, keys)
    }

    console.log("finished all uploads for network:", base.name)
    latestFirstEditionArticleIdChain = (await fetchLatestFirstEditionArticle(client)).articleId
    console.log("latest first edition article ID on chain:", latestFirstEditionArticleIdChain)
    latestFirstEditionArticleAlgolia = await firstEditionAlgoliaFetcher.fetchLatestArticle()
    latestFirstEditionArticleIdAlgolia = latestFirstEditionArticleAlgolia ? BigInt(latestFirstEditionArticleAlgolia.articleId) :0n
    console.log("latest first edition article ID on Algolia:", latestFirstEditionArticleIdAlgolia)
}

const cacheArticle = async (id:bigint, web3Client:PublicClient, indexName:string, keys:ApiKeys) => {
    console.log("syncing articleId: ", id)
    const articleMetadata = await fetchArticleById(web3Client, id);
    if (articleMetadata === null) {
        throw new Error("null article")
    }
    const articleImageBuffer = await getImageBufferFromURL(articleMetadata.articleImageURL.toString());
    const articleImageKey = await pushToR2("daily-pepe-images", "article-"+articleMetadata.articleId.toString(), articleImageBuffer, keys);
    const articleNFTImageBuffer = await getImageBufferFromURL(articleMetadata.articleNFTImageURL.toString());
    const articleNFTImageKey = await pushToR2("daily-pepe-images", "NFT-"+articleMetadata.articleId.toString(), articleNFTImageBuffer, keys);
    articleMetadata.articleImageURL = `https://images.thedailypepe.com/${articleImageKey}`;
    articleMetadata.articleNFTImageURL = `https://images.thedailypepe.com/${articleNFTImageKey}`;
    articleMetadata.previewArticleImageURL = `https://images.thedailypepe.com/${articleImageKey}`;
    articleMetadata.previewArticleNFTImageURL = `https://images.thedailypepe.com/${articleNFTImageKey}`;
    await pushArticlesToAlgolia(algoliaClient(keys), indexName, [articleMetadata]);
}

const cacheFirstEditionArticle = async (id:bigint, web3Client:PublicClient, indexName:string, keys:ApiKeys) => {
    console.log("syncing first edition articleId: ", id)
    const articleMetadata = await fetchFirstEditionArticleById(web3Client, id)
    if (articleMetadata === null) {
        throw new Error("null article")
    }
    const articleNFTImageBuffer = await getImageBufferFromURL(articleMetadata.articleNFTImageURL.toString())
    const articleNFTImageKey = await pushToR2("daily-pepe-images", "first-edition-NFT-"+articleMetadata.articleId.toString(), articleNFTImageBuffer, keys)
    articleMetadata.articleNFTImageURL = `https://images.thedailypepe.com/${articleNFTImageKey}`
    articleMetadata.articleImageURL = `https://images.thedailypepe.com/article-${articleMetadata.articleId.toString()}`
    articleMetadata.previewArticleNFTImageURL = `https://images.thedailypepe.com/${articleNFTImageKey}`
    articleMetadata.previewArticleImageURL = `https://images.thedailypepe.com/article-${articleMetadata.articleId.toString()}`
    await pushFirstEditionArticlesToAlgolia(algoliaClient(keys), indexName, [articleMetadata] )
}

main().catch((error) => {
    console.error(error);
});