import React from "react";
import { ApiKeyEncryptor } from "./misc/ApiKeyEncryptor"
import { CreateArticleNFTForm } from "./articleNFT/createArticleNFTForm";
import { AdminMintArticleNFTForm } from "./articleNFT/adminMintArticleNFT";
import { AddAdminArticleNFT } from "./articleNFT/addAdminArticleNFT";
import { SetIssueAvailabilityArticleNFT } from "./articleNFT/setIssueAvailabilityArticleNFT"
import { AddMinterArticleNFT } from "./articleNFT/addMinterArticleNFT";
import { SetURIArticleNFT } from "./articleNFT/setUriArticleNFT";
import { RemoveMinterArticleNFT } from "./articleNFT/removeMinterArticleNFT";
import { RemoveAdminArticleNFT } from "./articleNFT/removeAdminArticleNFT";
import { CreateFirstEditionArticleNFT } from "./firstEditionNFT/createNewArticleFirstEdition";
import { SetURIFirstEditionNFT } from "./firstEditionNFT/setUriFirstEdition";
import { AddAdminFirstEditionNFT } from "./firstEditionNFT/addAdminFirstEditionNFT";
import { AddMinterFirstEditionNFT } from "./firstEditionNFT/addMinterFirstEditionNFT";
import { RemoveAdminFirstEditionNFT } from "./firstEditionNFT/removeAdminFirstEditionNFT";
import { RemoveMinterFirstEditionNFT } from "./firstEditionNFT/removeMinterFirstEditionNFT";
import { AddAdminMintController } from "./mintController/addAdminMintController";
import { RemoveAdminMintController } from "./mintController/removeAdminMintController";
import { SetAffiliateShareDivisor } from "./mintController/setAffiliateShareDivisor";
import { SetBenefactorMintController } from "./mintController/setBenefactor";
import { SetMintPrice } from "./mintController/setMintPrice";
import { WithdrawMintController } from "./mintController/withdrawMintController";
import { AddAdminAuction } from "./auction/addAdminAuction";
import { RemoveAdminAuction } from "./auction/removeAdminAuction";
import { CreateTokenAndStartAuction } from "./auction/createTokenAndStartAuction";
import { PayoutAllAuction } from "./auction/payoutAllAuction";
import { SetBenefactorAuction } from "./auction/setBenefactorAuction";
import { WithdrawAuction } from "./auction/withdrawAuction";
import { ParseEther } from "./misc/etherConverter";
import { getNetwork } from '@wagmi/core'
import {
  getArticleNFTAddress, 
  getAuctionAddress, 
  getMintControllerAddress,
  getProxyAdminAddress
} from "../constants/addressBook"
import { DecryptKeysButton } from "./uploadForms/DecryptKeysButton";
import { ArticleForm } from "./uploadForms/ArticleForm";
// import { SetContractImplementationArticleNFT } from "./articleNFT/setArticleNFTContractImplementation";
// import { SetContractImplementationFirstEditionNFT } from "./firstEditionNFT/setFirstEditionNFTContractImplementation";

const ControlPanel: React.FC = () => {
  const network = getNetwork().chain?.name
  return (
  <div>
    <h2>Quick Upload Form</h2>
      <DecryptKeysButton/>
      <ArticleForm/>
    <br/>

    <h2>Article NFT Controls</h2>
      <CreateArticleNFTForm/>
      <AdminMintArticleNFTForm/>
      <SetURIArticleNFT/>
      <SetIssueAvailabilityArticleNFT/>
      <AddAdminArticleNFT/>
      <AddMinterArticleNFT/>
      <RemoveAdminArticleNFT/>
      <RemoveMinterArticleNFT/>
      {/* <SetContractImplementationArticleNFT/> this can result is really weird behavior if not done carefully so I've commented it out*/}
      <br/>

    <h2>First Edition Article NFT Controls</h2>
      <CreateFirstEditionArticleNFT/>
      <SetURIFirstEditionNFT/>
      <AddAdminFirstEditionNFT/>
      <AddMinterFirstEditionNFT/>
      <RemoveAdminFirstEditionNFT/>
      <RemoveMinterFirstEditionNFT/>
      {/* <SetContractImplementationFirstEditionNFT/> this can result is really weird behavior if not done carefully so I've commented it out*/}
      <br/>
    
    <h2>Mint Controller</h2>
      <AddAdminMintController/>
      <RemoveAdminMintController/>
      <SetAffiliateShareDivisor/>
      <SetBenefactorMintController/>
      <SetMintPrice/>
      <WithdrawMintController/>
      <br/>

    <h2>Auction</h2>
      <AddAdminAuction/>
      <RemoveAdminAuction/>
      <CreateTokenAndStartAuction/>
      <PayoutAllAuction/>
      <SetBenefactorAuction/>
      <WithdrawAuction/>

    <h2>Misc Utils</h2>
     <ParseEther/>
     <ApiKeyEncryptor/>

    <h2>Deployment Addresses</h2>
      <div>ArticleNFT: {getArticleNFTAddress(network)}</div>
      <div>FirstEditionNFT: {getArticleNFTAddress(network)}</div>
      <div>MintController: {getMintControllerAddress(network)}</div>
      <div>Auction: {getAuctionAddress(network)}</div>
      <div>ProxyAdmin: {getProxyAdminAddress(network)}</div>
  </div>
)
};

export default ControlPanel