import { Address } from "viem"

const addressBook = {
  hardhat: {
    ArticleNFTAddress: '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9' as Address,
    MintControllerAddress: '0x5FC8d32690cc91D4c39d9d3abcBD16989F875707' as Address,
    FirstEditionNFTAddress: '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9' as Address,
    AuctionAddress: '0x0165878A594ca255338adfa4d48449f69242Eb8F' as Address,
    ProxyAdmin: '0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0' as Address,
    BenefactorAddress: '0xf47b87217beAC168645790e887CEA643AE180654' as Address,
  },
  sepolia: {
    ArticleNFTAddress: '0x91412a98f12938e403AC45270a4C3F1AD8B6A18f' as Address,
    MintControllerAddress: '0x11edC3282d4F47F281dB5Ca5017F87ED8E39b4Ed' as Address,
    FirstEditionNFTAddress: '0x4cFb412612714c90580A4B46EA629DF517C34af0' as Address,
    AuctionAddress: '0x8E69D8Dc1982d01d321e7c7c024DFDcb83a6fFd2' as Address,
    ProxyAdmin: '0xd8ae7dea6051dCf07f96247ED560024bC9a08817' as Address,
    BenefactorAddress: '0xf47b87217beAC168645790e887CEA643AE180654' as Address,
  },
  base: {
    ArticleNFTAddress: '0x351A892C0faea52B7F7EAb67913387a4Cd775e41' as Address,
    MintControllerAddress: '0xc09658F7572Bd4f5B100872EE5001609536E3f78' as Address,
    FirstEditionNFTAddress: '0x6022F55D53AE83438161cABd986a147f4F2A21Ac' as Address,
    AuctionAddress: '0x662976b56Efa0516440131e1E8f1a01066119Ab0' as Address,
    ProxyAdmin: '0x6Aa46f74F78437653E98e0b6BBa594C761070464' as Address,
    BenefactorAddress: '0xf47b87217beAC168645790e887CEA643AE180654' as Address,
  },
}

export const getBenefactorAddress = (network:string|undefined):Address => {
  switch (network) {
  case 'Hardhat':
    return addressBook.hardhat.BenefactorAddress
  case 'Sepolia':
    return addressBook.sepolia.BenefactorAddress
  case 'Base':
    return addressBook.base.BenefactorAddress
  default:
    console.log("error! network is undefined")
    return '' as Address
  }
}

export const getArticleNFTAddress = (network:string|undefined):Address => {
  switch (network) {
  case 'Hardhat':
    return addressBook.hardhat.ArticleNFTAddress
  case 'Sepolia':
    return addressBook.sepolia.ArticleNFTAddress
  case 'Base':
    return addressBook.base.ArticleNFTAddress
  default:
    console.log("error! network is undefined")
    return '' as Address
  }
}

export const getMintControllerAddress = (network:string|undefined):Address => {
  switch (network) {
    case 'Hardhat':
      return addressBook.hardhat.MintControllerAddress
    case 'Sepolia':
      return addressBook.sepolia.MintControllerAddress
    case 'Base':
      return addressBook.base.MintControllerAddress
    default:
      console.log("error! network is undefined")
      return '' as Address
  }
}

export const getFirstEditionNFTAddress = (network:string|undefined):Address => {
  switch (network) {
    case 'Hardhat':
      return addressBook.hardhat.FirstEditionNFTAddress
    case 'Sepolia':
      return addressBook.sepolia.FirstEditionNFTAddress
    case 'Base':
      return addressBook.base.FirstEditionNFTAddress
    default:
      console.log("error! network is undefined")
      return '' as Address
  }
}

export const getAuctionAddress = (network:string|undefined):Address => {
  switch (network) {
    case 'Hardhat':
      return addressBook.hardhat.AuctionAddress
    case 'Sepolia':
      return addressBook.sepolia.AuctionAddress
    case 'Base':
      return addressBook.base.AuctionAddress
    default:
      console.log("error! network is undefined")
      return '' as Address
  }
}

export const getProxyAdminAddress = (network:string|undefined):Address => {
  switch (network) {
    case 'Hardhat':
      return addressBook.hardhat.ProxyAdmin
    case 'Sepolia':
      return addressBook.sepolia.ProxyAdmin
    case 'Base':
      return addressBook.base.ProxyAdmin
    default:
      console.log("error! network is undefined")
      return '' as Address
  }
}