import {
    S3Client,
    PutObjectCommand
  } from "@aws-sdk/client-s3";
import { ApiKeys } from "../../../context/ApiKeysContext";

export async function pushToR2 (bucket: string, key: string, body: Buffer, keys: ApiKeys): Promise<string> {
  const S3 = new S3Client({
    region: "auto",
    endpoint: `https://${keys.cloudflareAccountId}.r2.cloudflarestorage.com`,
    credentials: {
      accessKeyId: keys.cloudflareAccessKeyId,
      secretAccessKey: keys.cloudflareSecretKey,
    },
  });

    const params = {
        Bucket: bucket,
        Key: key,
        Body: body,
        ContentType: 'image/jpeg',
    };
    try {
        const poc = new PutObjectCommand(params);
        await S3.send(poc);
        console.log(`Successfully pushed to R2 bucket: ${bucket} with key: ${key}`);
        return key;
    } catch (err) {
        console.error(`Failed to push to R2 bucket: ${bucket} with key: ${key}`, err);
        throw err;
    }
};
