import React, { useState } from 'react';
import { useSignMessage } from 'wagmi';
import { keccak256, toBytes } from 'viem';
import { Buffer } from 'buffer';

const xorEncrypt = (data: string, key: string): string => {
  const dataBytes = new TextEncoder().encode(data);
  const keyBytes = new TextEncoder().encode(key);
  const result = dataBytes.map((byte, i) => byte ^ keyBytes[i % keyBytes.length]);
  return Buffer.from(result).toString('base64');
};

const xorDecrypt = (encrypted: string, key: string): string => {
  const encryptedBytes = Buffer.from(encrypted, 'base64');
  const keyBytes = new TextEncoder().encode(key);
  const result = encryptedBytes.map((byte, i) => byte ^ keyBytes[i % keyBytes.length]);
  return new TextDecoder().decode(new Uint8Array(result));
};

export const ApiKeyEncryptor = () => {
  const [apiKey, setApiKey] = useState('');
  const [encrypted, setEncrypted] = useState('');
  const [encryptedInput, setEncryptedInput] = useState('');
  const [decrypted, setDecrypted] = useState('');

  const { signMessageAsync } = useSignMessage();

  const handleEncrypt = async () => {
    const sig = await signMessageAsync({ message: 'Decrypt Pinata API Key' });
    const key = keccak256(toBytes(sig));
    const encryptedKey = xorEncrypt(apiKey, key);
    setEncrypted(encryptedKey);
  };

  const handleDecrypt = async () => {
    const sig = await signMessageAsync({ message: 'Decrypt Pinata API Key' });
    const key = keccak256(toBytes(sig));
    const decryptedKey = xorDecrypt(encryptedInput, key);
    setDecrypted(decryptedKey);
  };

  return (
    <div>
      <h3>Encrypt API Key</h3>
      <input
        type="text"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
        placeholder="Enter API Key"
      />
      <button onClick={handleEncrypt}>Sign & Encrypt</button>

      {encrypted && (
        <div>
          <p>Encrypted API Key:</p>
          <pre>{encrypted}</pre>
        </div>
      )}

      <h3>Decrypt Encrypted API Key</h3>
      <input
        type="text"
        value={encryptedInput}
        onChange={(e) => setEncryptedInput(e.target.value)}
        placeholder="Enter Encrypted Key (base64)"
      />
      <button onClick={handleDecrypt}>Sign & Decrypt</button>

      {decrypted && (
        <div>
          <p>Decrypted API Key:</p>
          <pre>{decrypted}</pre>
        </div>
      )}
    </div>
  );
};

