import React, { useState, useEffect, useCallback } from 'react';
import { useAccount } from 'wagmi';
import { useApiKeys } from '../../context/ApiKeysContext';

export const DecryptKeysButton = () => {
  const { address: currentAddress, isConnected } = useAccount();
  const { decryptKeys, decryptedKeys } = useApiKeys();

  const [disabledReason, setDisabledReason] = useState<string | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const adminAddress = (process.env.REACT_APP_ETHEREUM_ADMIN_ACCOUNT as string).toLowerCase();

  const isDecrypted = useCallback(() => {
    return (
      decryptedKeys !== null &&
      Object.values(decryptedKeys).every((val) => typeof val === 'string' && val.length > 0)
    );
  }, [decryptedKeys]);

  useEffect(() => {
    const checkState = () => {
      if (!isConnected || !currentAddress) {
        setDisabledReason('Connect your Ethereum wallet to decrypt.');
        setButtonDisabled(true);
        return;
      }

      if (currentAddress.toLowerCase() !== adminAddress) {
        setDisabledReason('Only the admin wallet can decrypt keys.');
        setButtonDisabled(true);
        return;
      }

      if (isDecrypted()) {
        setDisabledReason('Keys have already been decrypted.');
        setButtonDisabled(true);
        return;
      }

      setDisabledReason(null);
      setButtonDisabled(false);
    };

    checkState();
  }, [currentAddress, isConnected, decryptedKeys, adminAddress, isDecrypted]); // 👈 Include `decryptedKeys`

  const handleClick = async () => {
    console.log("env vars", process.env)
    try {
      await decryptKeys();
    } catch (err) {
      console.error('Decryption failed:', err);
    }
  };

  return (
    <div>
      <button onClick={handleClick} disabled={buttonDisabled}>
        Decrypt API Keys
      </button>

      {disabledReason && <p style={{ color: 'gray' }}>{disabledReason}</p>}

      <p>
        Decryption Status:{' '}
        {isDecrypted() ? (
          <span style={{ color: 'green' }}>✅ Decrypted</span>
        ) : (
          <span style={{ color: 'red' }}>❌ Not Decrypted</span>
        )}
      </p>
    </div>
  );
};

