import { SearchClient, SearchIndex } from 'algoliasearch';
import { Article } from "../../types/apiTypes";
export class AlgoliaFetcher {

    private algoliaSearch: SearchClient;
    private algoliaIndex: SearchIndex; 
    constructor(algoliasearch: SearchClient, indexName: string) {
        this.algoliaSearch = algoliasearch;
        this.initIndex(indexName)
    }

    initIndex(indexName: string): void {
        this.algoliaIndex = this.algoliaSearch.initIndex(indexName);
    }

    async fetchLatestArticle(): Promise<Article> {
        const res = await this.algoliaIndex.search("", {page: 0, hitsPerPage: 1})
        const data = res.hits[0] as unknown as Article
        return data
    }

    fetchArticleNftData(articleId: bigint): Promise<any> {
        throw new Error("Method not implemented.");
    }

    fetchArticleImage(articleId: bigint): Promise<any> {
        throw new Error("Method not implemented")
    }

    getLatest(): Promise<any> {
        return this.getArticlePage(0, 1).then(res => {
            const data = res.hits[0]
            return data;
        });
    }

    async getArticlePage(page: number, numArticles: number): Promise<any> {
        return this.algoliaIndex.search("", {
            hitsPerPage: numArticles,
            page: page
        }).then(res => {
            return {hits: res.hits, totalPages: res.nbPages}
        });
    }

    getPageCount(pageSize: number): Promise<number> {
        return this.algoliaIndex.search("", {
            hitsPerPage: pageSize,
            page: 0
        }).then(res => res.nbPages);
    }

    async getByCid(cid: string): Promise<any> {
        const data = await this.algoliaIndex.findObject(hit => (hit as any).articleId === cid);
        return data
    }
}