import React, { createContext, useContext, useState } from 'react';
import { useSignMessage } from 'wagmi';
import { keccak256, toBytes } from 'viem';

export type ApiKeys = {
  pinataJwt: string;
  cloudflareTokenValue: string;
  cloudflareAccountId: string;
  cloudflareAccessKeyId: string;
  cloudflareSecretKey: string;
  algoliaAppId: string;
  algoliaApiKey: string;
};

// 🔒 Module-level variable to store latest keys
let cachedDecryptedKeys: ApiKeys | null = null;

const ApiKeysContext = createContext<{
  decryptedKeys: ApiKeys | null;
  decryptKeys: () => Promise<void>;
}>({
  decryptedKeys: null,
  decryptKeys: async () => {},
});

export const ApiKeysProvider = ({ children }: { children: React.ReactNode }) => {
  const [decryptedKeys, setDecryptedKeys] = useState<ApiKeys | null>(null);
  const { signMessageAsync } = useSignMessage();

  const xorDecrypt = (encrypted: string, key: string): string => {
    const binary = atob(encrypted);
    const encryptedBytes = new Uint8Array([...binary].map(char => char.charCodeAt(0)));
    const keyBytes = new TextEncoder().encode(key);
    const result = encryptedBytes.map((byte, i) => byte ^ keyBytes[i % keyBytes.length]);
    return new TextDecoder().decode(result);
  };

  const decryptKeys = async () => {
    const signature = await signMessageAsync({ message: 'Decrypt Pinata API Key' });
    const key = keccak256(toBytes(signature));

    const getEnvVar = (name: string): string => {
      const val = process.env[name] as string;
      if (!val) throw new Error(`Missing environment variable: ${name}`);
      return val;
    };

    const decrypted: ApiKeys = {
      pinataJwt: xorDecrypt(getEnvVar('REACT_APP_ENCRYPTED_PINATA_JWT'), key),
      cloudflareTokenValue: xorDecrypt(getEnvVar('REACT_APP_ENCRYPTED_CLOUDFLARE_TOKEN_VALUE'), key),
      cloudflareAccountId: xorDecrypt(getEnvVar('REACT_APP_ENCRYPTED_CLOUDFLARE_ACCOUNT_ID'), key),
      cloudflareAccessKeyId: xorDecrypt(getEnvVar('REACT_APP_ENCRYPTED_CLOUDFLARE_ACCESS_KEY_ID'), key),
      cloudflareSecretKey: xorDecrypt(getEnvVar('REACT_APP_ENCRYPTED_CLOUDFLARE_SECRET_KEY'), key),
      algoliaAppId: xorDecrypt(getEnvVar('REACT_APP_ENCRYPTED_ALGOLIA_APP_ID'), key),
      algoliaApiKey: xorDecrypt(getEnvVar('REACT_APP_ENCRYPTED_ALGOLIA_API_KEY'), key),
    };
    console.log("decrypted:", decrypted)

    setDecryptedKeys(decrypted);
    cachedDecryptedKeys = decrypted; // ✅ Store for use outside context
  };

  return (
    <ApiKeysContext.Provider value={{ decryptedKeys, decryptKeys }}>
      {children}
    </ApiKeysContext.Provider>
  );
};

export const useApiKeys = () => useContext(ApiKeysContext);

// ✅ Standalone function (no args) using module-level cache
export const areKeysDecrypted = (): boolean => {
  return (
    cachedDecryptedKeys !== null &&
    Object.values(cachedDecryptedKeys).every((val) => typeof val === 'string' && val.length > 0)
  );
};
