import { algoliasearch, SearchClient } from 'algoliasearch';
import { Article, FirstEditionArticle } from '../types/apiTypes';
import { ApiKeys } from '../../../context/ApiKeysContext';

// Clean this up later
export const algoliaClient = (keys:ApiKeys):any => {
  return algoliasearch(keys.algoliaAppId, keys.algoliaApiKey);
} 

export async function pushArticlesToAlgolia(
  client: SearchClient,
  indexName: string,
  records: Article[]
) {
  const index = client.initIndex(indexName);
  const serializableRecords = records.map(record => {
    return serializeArticle(record)
  })
  await index.saveObjects(serializableRecords);
}

export async function pushFirstEditionArticlesToAlgolia(
  client: SearchClient,
  indexName: string,
  records: FirstEditionArticle[]
) {
  const index = client.initIndex(indexName);
  const serializableRecords = records.map(record => {
    return serializeFirstEditionArticle(record)
  })
  await index.saveObjects(serializableRecords);
}

export const serializeArticle = (record:Article):any => {
  return {
    ...record,
    objectID: record.articleId.toString(),
    mintStart: record.mintStart.toString(),
    mintEnd: record.mintEnd.toString(),
    mintPrice: record.mintPrice.toString(),
    articleId: record.articleId.toString(),
  }
}

export const serializeFirstEditionArticle = (record:FirstEditionArticle):any => {
  return {
    ...record,
    objectID: record.articleId.toString(),
    auctionEnd: record.auctionEnd.toString(),
    articleId: record.articleId.toString(),
  }
}