import React, { useState, useEffect } from "react";
import { useApiKeys, areKeysDecrypted } from "../../context/ApiKeysContext";
import { fetchNextArticleId, updateIndicies } from "../../algoliaCFUploader/algoliaUploader";
import { writeContract, getNetwork } from "@wagmi/core";
import articleNFTABI from "../../abis/articleNFTABI";
import mintControllerABI from "../../algoliaCFUploader/lib/abi/mintControllerAbi";
import auctionAbi from "../../algoliaCFUploader/lib/abi/auctionAbi";
import { getAuctionAddress, getMintControllerAddress, getArticleNFTAddress, getBenefactorAddress } from "../../constants/addressBook";

interface FormData {
  articleImage: File | null;
  regularNFT: File | null;
  firstEditionNFT: File | null;
  title: string;
  articleText: string;
  tags: string[];
  date: string;
  author: string;
  artist: string;
  mintPrice: bigint;
}

export const ArticleForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    articleImage: null,
    regularNFT: null,
    firstEditionNFT: null,
    title: "",
    articleText: "",
    tags: [],
    date: formatDate(new Date()),
    author: "Belvis",
    artist: "Emperor Frenguin",
    mintPrice: 1000000000000000n,
  });

  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [metadataHashes, setMetadataHashes] = useState<{ [key: string]: string }>({});

  const apiKeys = useApiKeys();
  const decrypted = areKeysDecrypted();

  useEffect(() => {
    if (decrypted) {
      console.log("API keys ready to use:", apiKeys);
    }
  }, [decrypted, apiKeys]);

  function formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === "tags") {
      setFormData({ ...formData, tags: value.split(",").map(tag => tag.trim()) });
    } else if (name === "date") {
      const dateObj = new Date(value);
      setFormData({ ...formData, date: formatDate(dateObj) });
    } else if (name === "mintPrice") {
      setFormData({ ...formData, mintPrice: BigInt(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];
      if (file.type === "image/png") {
        setFormData({ ...formData, [name]: file });
      } else {
        alert("Only .png files are allowed.");
        e.target.value = ""; // Clear the input
      }
    }
  };
  

  const uploadToPinata = async (
    file: File | Blob,
    fileName: string,
    isJSON = false
  ): Promise<string> => {
    console.log("testing pinata....");
    console.log("apiKey:", apiKeys.decryptedKeys.pinataJwt, apiKeys);
  
    const options = {
      method: "GET",
      headers: { Authorization: `Bearer ${apiKeys.decryptedKeys.pinataJwt}` },
    };
  
    fetch("https://api.pinata.cloud/data/testAuthentication", options)
      .then((response) => response.json())
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  
    setUploadStatus(`Uploading ${fileName}...`);
  
    const data = new FormData();
  
    // Create a File object if needed, to assign a custom name
    let renamedFile: File;
  
    if (isJSON) {
      const jsonBlob = new Blob([JSON.stringify(file)], {
        type: "application/json",
      });
      renamedFile = new File([jsonBlob], `${fileName}.json`, {
        type: "application/json",
      });
    } else if (file instanceof File) {
      renamedFile = new File([file], fileName || file.name, {
        type: file.type,
      });
    } else {
      renamedFile = new File([file], fileName, { type: file.type || "application/octet-stream" });
    }
  
    data.append("file", renamedFile, renamedFile.name); // This name is used in IPFS structure
  
    // Optional: Add pinata metadata (e.g., same label for UI convenience)
    const metadata = {
      name: fileName,
    };
    data.append("pinataMetadata", JSON.stringify(metadata));
  
    console.log("pinata key:", apiKeys.decryptedKeys.pinataJwt);
  
    const res = await fetch("https://api.pinata.cloud/pinning/pinFileToIPFS", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${apiKeys.decryptedKeys.pinataJwt}`,
      },
      body: data,
    });
  
    if (!res.ok) {
      throw new Error(`Failed to upload ${fileName}`);
    }
  
    const result = await res.json();
    setUploadStatus(`${fileName} uploaded successfully.`);
    return result.IpfsHash;
  };
  
  const replaceSpacesWithUnderscores = (input: string): string => {
    return input.replace(/\s+/g, "_");
  };

  const waitUntilTimestamp = async (targetTimestamp: number, setUploadStatus:any): Promise<void> => {
    while (true) {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const remaining = targetTimestamp - currentTimestamp;
      setUploadStatus("Waiting for article to be mintable - "+remaining+" seconds remaining");
    
      if (remaining <= 0) {
        break;
      }
  
      // Sleep for a short interval (e.g., 1 second or less if close)
      const sleepTime = Math.min(remaining, 1) * 1000;
      await new Promise((resolve) => setTimeout(resolve, sleepTime));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setUploadStatus("getting next article ID...");

    e.preventDefault();
    console.log("Form Data to send:", formData);

    try {
      if (!decrypted) {
        setUploadStatus("API keys are not decrypted.");
        return;
      }

      const articleId: bigint = await fetchNextArticleId();
      const hashes: { [key: string]: string } = {};
      const baseTitle = replaceSpacesWithUnderscores(formData.title)
      const articleImageFileName = baseTitle+"-"+articleId+".png"
      const NFTImageFileName = baseTitle+"-NFT-"+articleId+".png"
      const FENFTImageFileName = baseTitle+"-NFT-FE-"+articleId+".png"

      if (formData.articleImage) {
        hashes.articleImage = await uploadToPinata(formData.articleImage, articleImageFileName);
      }
      if (formData.regularNFT) {
        hashes.regularNFT = await uploadToPinata(formData.regularNFT, NFTImageFileName);
      }
      if (formData.firstEditionNFT) {
        hashes.firstEditionNFT = await uploadToPinata(formData.firstEditionNFT, FENFTImageFileName);
      }

      setUploadStatus("All images uploaded successfully.");

      const regularNFTMetadata = {
        name: `The Daily Pepe Article: ${articleId}`,
        description:
          "The Daily Pepe is an online news publication for commemorating historic events as they happen (in the form of rare pepes)",
        external_link: "https://thedailypepe.com",
        image: `ipfs://${hashes.regularNFT}`,
        articleImage: `ipfs://${hashes.articleImage}`,
        author: formData.author,
        artist: formData.artist,
        date: formData.date,
        title: formData.title,
        text: formData.articleText,
        tags: formData.tags,
      };

      const firstEditionMetadata = {
        name: `The Daily Pepe First Edition Article: ${articleId}`,
        description:
          "The Daily Pepe is an online news publication for commemorating historic events as they happen (in the form of rare pepes)",
        external_link: "https://thedailypepe.com",
        image: `ipfs://${hashes.firstEditionNFT}`,
        articleImage: `ipfs://${hashes.articleImage}`,
        author: formData.author,
        artist: formData.artist,
        date: formData.date,
        title: formData.title,
        text: formData.articleText,
        tags: formData.tags,
      };

      console.log("normal metadata", regularNFTMetadata)
      console.log("first edition metadata", firstEditionMetadata)
      const regularNFTMetadataFileName = replaceSpacesWithUnderscores(formData.title) + "-" + articleId.toString() + ".json"
      const firstEditionNFTMetadataFileName = replaceSpacesWithUnderscores(formData.title) + "-FE-" + articleId.toString() + ".json"

      //@ts-ignore
      const regularHash = await uploadToPinata(regularNFTMetadata, regularNFTMetadataFileName, true);
      //@ts-ignore
      const firstEditionHash = await uploadToPinata(firstEditionMetadata, firstEditionNFTMetadataFileName, true);

      setMetadataHashes({
        regularMetadata: regularHash,
        firstEditionMetadata: firstEditionHash,
      });

      setUploadStatus("All metadata uploaded successfully.");

      const startTime = BigInt(Math.floor(Date.now() / 1000) + 60);
      const endDate = new Date();
      endDate.setUTCDate(endDate.getUTCDate() + 8);
      endDate.setUTCHours(0, 0, 0, 0);
      const endTime = BigInt(Math.floor(endDate.getTime() / 1000));

      const uri = `ipfs://${regularHash}`;
      const network = await getNetwork()
      setUploadStatus("Submitting article creation transaction...");
      await writeContract({
        address: getArticleNFTAddress(network.chain?.name),
        abi: articleNFTABI,
        functionName: "createNewArticle",
        args: [startTime, endTime, uri],
      });

      setUploadStatus("Setting mint price...");
      await writeContract({
        address: getMintControllerAddress(network.chain?.name),
        abi: mintControllerABI,
        functionName: "setMintPrice",
        args: [articleId, formData.mintPrice],
      });

      const firstEditionUri = `ipfs://${firstEditionHash}`;

      setUploadStatus("Creating First Edition auction...");
      await writeContract({
        address: getAuctionAddress(network.chain?.name), // replace
        abi: auctionAbi,
        functionName: "createTokenAndStartAuction",
        args: [firstEditionUri, endTime],
      });

      setUploadStatus("Updating indices...");
      await updateIndicies(apiKeys.decryptedKeys);

      await waitUntilTimestamp(Number(startTime), setUploadStatus)
      setUploadStatus("Minting 10 articles to benefactor...");
      await writeContract({
        address: getArticleNFTAddress(network.chain?.name), // replace
        abi: articleNFTABI,
        functionName: "mint",
        args: [getBenefactorAddress(network.chain?.name), articleId, BigInt(10)],
      });

      setUploadStatus("Done.");

    } catch (error: any) {
      setUploadStatus(`Error: ${error.message}`);
    }
  };

  const renderImagePreview = (file: File | null, label: string) => {
    if (!file) return null;
    const url = URL.createObjectURL(file);
    return (
      <div>
        <p>{label}</p>
        <img src={url} alt={label} style={{ maxWidth: "200px", maxHeight: "200px" }} />
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Article Image:</label>
        <input type="file" name="articleImage" onChange={handleFileChange} />
        {renderImagePreview(formData.articleImage, "Article Image Preview")}
      </div>

      <div>
        <label>Regular NFT:</label>
        <input type="file" name="regularNFT" onChange={handleFileChange} />
        {renderImagePreview(formData.regularNFT, "Regular NFT Preview")}
      </div>

      <div>
        <label>First Edition NFT:</label>
        <input type="file" name="firstEditionNFT" onChange={handleFileChange} />
        {renderImagePreview(formData.firstEditionNFT, "First Edition NFT Preview")}
      </div>

      <div>
        <label>Title:</label>
        <input type="text" name="title" value={formData.title} onChange={handleChange} />
      </div>

      <div>
        <label>Article Text:</label>
        <textarea name="articleText" value={formData.articleText} onChange={handleChange} />
      </div>

      <div>
        <label>Tags (comma-separated):</label>
        <input type="text" name="tags" onChange={handleChange} />
      </div>

      <div>
        <label>Date:</label>
        <input type="date" name="date" onChange={handleChange} />
      </div>

      <div>
        <label>Author:</label>
        <input type="text" name="author" value={formData.author} onChange={handleChange} />
      </div>

      <div>
        <label>Artist:</label>
        <input type="text" name="artist" value={formData.artist} onChange={handleChange} />
      </div>

      <div>
        <label>Article Mint Price (in wei):</label>
        <input type="text" name="mintPrice" value={formData.mintPrice.toString()} onChange={handleChange} />
      </div>

      <button type="submit">Submit</button>

      {uploadStatus && <p>Status: {uploadStatus}</p>}

      {metadataHashes.regularMetadata && (
        <p>Regular NFT Metadata IPFS Hash: ipfs://{metadataHashes.regularMetadata}</p>
      )}
      {metadataHashes.firstEditionMetadata && (
        <p>First Edition Metadata IPFS Hash: ipfs://{metadataHashes.firstEditionMetadata}</p>
      )}
    </form>
  );
};
