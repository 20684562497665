import { 
  Address, 
  Chain,
  PublicClient,
} from 'viem'
import { 
  getTheDailyPepeArticleNFTAddress, 
  getTheDailyPepeMintControllerAddress,
  getTheDailyPepeFirstEditionAuctionAddress,
  getTheDailyPepeFirstEditionNFTAddress,
} from '../constants/addressBook'
import mintControllerABI from '../abi/mintControllerAbi'
import articleNFTABI from '../abi/articleNftAbi'
import firstEditionArticleAbi from '../abi/firstEditionArticleAbi'
import auctionAbi from '../abi/auctionAbi'
import { Article, FirstEditionArticle, TimeRange, RawArticleURIData } from '../types/apiTypes'

const privateIPFSEndpoint = 'https://api.thedailypepe.com/ipfs/'


export const fetchNextArticleId_ = async(client:PublicClient):Promise<bigint> => {
  const data = await client.readContract({
    abi: articleNFTABI,
    address: getTheDailyPepeArticleNFTAddress(client.chain as Chain),
    functionName: 'nextId',
  })
  return data as bigint
}

export const fetchLatestArticle = async (client:PublicClient):Promise<Article> => {
  //get latest article Id
  var latestId = 0n
  const data = await client.readContract({
    abi: articleNFTABI,
    address: getTheDailyPepeArticleNFTAddress(client.chain as Chain),
    functionName: 'nextId',
  })
  latestId = (data as bigint) - 1n
  console.log("latestId", latestId)

  const latestArticle = await fetchArticleById(client, latestId)

  if (latestArticle === null) {
    throw new Error("Latest article should always exist") 
  }
  
  return latestArticle
}

export const fetchArticlePage = async (client:PublicClient, page: number, articlesPerPage: number):Promise<(Article|null)[]> => {
  //get latest article Id
  const latestArticle = await fetchLatestArticle(client)
  if (latestArticle === null) {
    console.log("failed to get latest article ID via web3")
    return []
  }

  const latestId = latestArticle.articleId
  const numArticles = latestId + 1n

  const promises:Promise<Article|null>[] = []
  for (let i = page * articlesPerPage; i < Math.max((page+1)*articlesPerPage, Number(numArticles)); i++) {
    promises.push(fetchArticleById(client, BigInt(i)))
  }

  return await Promise.all(promises)
}

export const fetchArticlePageCount = async (client:PublicClient, pageSize: number):Promise<number> => {
    //get latest article Id
    const latestArticle = await fetchLatestArticle(client)
    if (latestArticle === null) {
      console.log("failed to get latest article ID via web3")
      return 1
    }
  
    const latestId = latestArticle.articleId
    const numArticles = latestId + 1n
    return Number(numArticles / BigInt(pageSize) + 1n)
}

export const fetchArticleById = async (client:PublicClient, articleId: bigint):Promise<Article|null> => {
  const uri = await client.readContract({
    abi: articleNFTABI,
    address: getTheDailyPepeArticleNFTAddress(client.chain as Chain),
    functionName: 'uri',
    args: [articleId],
  }) as string

  if (uri === '') {
    return null
  }
  
  const uriData = JSON.parse(await fetchIPFS(uri, false)) as RawArticleURIData
  const promises:Promise<any>[] = []

  promises.push(
    (async () => {
      return fetchIPFS(uriData.articleImage, true)
    })(),
    (async () => {
      return fetchIPFS(uriData.image, true)
    })(),
    (async () => {
      return fetchArticleMintTime(client, articleId)
    })(),
    (async () => {
      return fetchArticleMintPrice(client, articleId)
    })(),
  )

  const dataArr = await Promise.all(promises)
  const cacheArticleImageURL = dataArr[0] as string
  const cacheArticleNFTImageURL = dataArr[1] as string
  const mintTimeRange = dataArr[2] as TimeRange
  const mintPrice = dataArr[3] as bigint

  const article:Article = {
    articleDate: uriData.date,
    articleTitle: uriData.title,
    articleImageURL: cacheArticleImageURL,
    articleNFTImageURL: cacheArticleNFTImageURL,
    previewArticleImageURL: cacheArticleImageURL,
    previewArticleNFTImageURL: cacheArticleNFTImageURL,
    articleText: uriData.text,
    articleAuthor: uriData.author,
    mintStart: mintTimeRange.start,
    mintEnd: mintTimeRange.end,
    mintPrice: mintPrice,
    tags: uriData.tags,
    articleId: articleId,

    //raw uri data
    name: uriData.name,
    description: uriData.description,
    external_link: uriData.external_link,
    imageIPFS: uriData.image,
    articleImageIPFS: uriData.articleImage
  }

  return article
}

//returns text content or the url of a local image, img must be set to true if retrieving an image
const fetchIPFS = async (_cid:string, img:boolean):Promise<string> => {
  let cid = _cid
  if (_cid.startsWith('ipfs://')) {
    cid = _cid.substring(7)//trim the ipfs:// part of the string so it's just the cid
  }
  const res = await fetch(privateIPFSEndpoint+cid)
  if (!res.ok) {
    console.log(_cid)
    throw new Error('error in IPFS request response. status code: '+res.status+'. '+res.statusText)
  }
  if (img) {
    const blob = await res.blob()
    const localURL = URL.createObjectURL(blob)
    if (!localURL) { //this is necessary because bun hasn't implemented createObjectURL yet
      return privateIPFSEndpoint+cid
    } else {
      return localURL
    }
  } else {
    return res.text();
  }
}

const fetchArticleMintTime = async (client:PublicClient, articleId:bigint):Promise<TimeRange|null> => {
  const data = await client.readContract({
    abi: articleNFTABI,
    address: getTheDailyPepeArticleNFTAddress(client.chain as Chain),
    functionName: 'issueAvailability',
    args: [articleId],
  }) as [bigint, bigint]
  const timeRangeData:TimeRange = {start: data[0], end: data[1]}

  if (timeRangeData.start === 0n && timeRangeData.end === 0n) {
    return null
  } else if (timeRangeData.start === 0n || timeRangeData.end === 0n) {
    throw new Error('one of the mint time range values is zero, this indicates an admin error on the contract level. Please report this to dev@thedailypepe.com. article Id: '+articleId.toString())
  }

  return timeRangeData
}

const fetchArticleMintPrice = async (client:PublicClient, articleId:bigint):Promise<bigint|null> => {
  const data = await client.readContract({
    address: getTheDailyPepeMintControllerAddress(client.chain as Chain),
    abi: mintControllerABI,
    functionName: 'mintPrices',
    args: [articleId],
  })

  const mintPrice = data as bigint
  if (mintPrice === 0n) {
    return null
  }

  return mintPrice
}

export const fetchFirstEditionArticleById = async (client:PublicClient, articleId:bigint):Promise<FirstEditionArticle|null> => {
  const uri = await client.readContract({
    abi: firstEditionArticleAbi,
    address: getTheDailyPepeFirstEditionNFTAddress(client.chain as Chain),
    functionName: 'tokenURI',
    args: [articleId],
  }) as string

  if (uri === '') {
    return null
  }

  const uriData = JSON.parse(await fetchIPFS(uri, false)) as RawArticleURIData
  const [articleImage, nftImage, auctionEnd] = await Promise.all([
    fetchIPFS(uriData.articleImage, true),
    fetchIPFS(uriData.image, true),
    fetchFirstEditionAuctionEnd(client, articleId)
  ])

  if (auctionEnd == null) {
    console.log("couldn't fetch auction end for id:", articleId)
    return null
  }
  

  const article:FirstEditionArticle = {
    articleDate: uriData.date,
    articleTitle: uriData.title,
    articleImageURL: articleImage,
    articleNFTImageURL: nftImage,
    previewArticleImageURL: articleImage,
    previewArticleNFTImageURL: nftImage,
    articleText: uriData.text,
    articleAuthor: uriData.author,
    auctionEnd: auctionEnd,
    tags: uriData.tags,
    articleId: articleId,

    //raw uri data
    name: uriData.name,
    description: uriData.description,
    external_link: uriData.external_link,
    imageIPFS: uriData.image,
    articleImageIPFS: uriData.articleImage
  }

  return article
}

export const fetchLatestFirstEditionArticle = async (client:PublicClient):Promise<FirstEditionArticle> => {
  const nextArticleId = await client.readContract({
    abi: firstEditionArticleAbi,
    address: getTheDailyPepeFirstEditionNFTAddress(client.chain as Chain),
    functionName: 'nextId',
  }) as bigint

  if (nextArticleId === 0n) {
    console.log("no first edition articles deployed")
    throw new Error("no first edition articles have been deployed yet")
  }
  const latestArticleId = nextArticleId - 1n
  return await fetchFirstEditionArticleById(client, latestArticleId) as unknown as FirstEditionArticle
}

export const fetchWinningBid = async (client:PublicClient, articleId:bigint):Promise<bigint> => {
  const winningBid = await client.readContract({
    abi: auctionAbi,
    address: getTheDailyPepeFirstEditionAuctionAddress(client.chain as Chain),
    functionName: 'winningBids',
    args: [articleId],
  }) as bigint

  return winningBid
}

export const fetchWinningAddress = async (client:PublicClient, articleId:bigint):Promise<Address> => {
  const winningAddress = await client.readContract({
    abi: auctionAbi,
    address: getTheDailyPepeFirstEditionAuctionAddress(client.chain as Chain),
    functionName: 'winningPayoutAddresses',
    args: [articleId],
  }) as Address

  return winningAddress
}

export const fetchMinBid = async (client:PublicClient, articleId:bigint):Promise<bigint> => {
  const minBid = await client.readContract({
    abi: auctionAbi,
    address: getTheDailyPepeFirstEditionAuctionAddress(client.chain as Chain),
    functionName: 'getMinBid',
    args: [articleId],
  }) as bigint

  return minBid
}

const fetchFirstEditionAuctionEnd = async (client:PublicClient, articleId:bigint):Promise<bigint> => {
  const auctionEnd = await client.readContract({
    abi: auctionAbi,
    address: getTheDailyPepeFirstEditionAuctionAddress(client.chain as Chain),
    functionName: 'auctionDeadlines',
    args: [articleId],
  }) as bigint

  return auctionEnd
}